export class Subscription {
  subscriptionId: string;
  userId: string;
  paymentMethodId: string;
  priceId: string;
  validityDate: Date | null;
  isActive: boolean;
  licenseCount: number;
  billingCycleAnchor: Date;
  cancelAt: Date | null;
  cancelAtPeriodEnd: boolean;
  collectionMethod: string;
  created: Date;
  currency: string;
  currentPeriodEnd: Date;
  currentPeriodStart: Date;
  customer: string;
  daysUntilDue: number | null;
  defaultPaymentMethodId: string;
  discountId: string;
  endedAt: Date | null;
  latestInvoiceId: string;
  nextPendingInvoiceItemInvoice: Date | null;
  pendingSetupIntentId: string;
  startDate: Date;
  status: string;
  planId: string;
  planAmount: number | null;
  planCurrency: string;
  planInterval: string;
  planIntervalCount: number | null;
  productName?: string;
  paymentMethod?: any;
  latestInvoice?: any;

  constructor(data: any) {
    this.subscriptionId = data.subscriptionId;
    this.userId = data.userId;
    this.paymentMethodId = data.paymentMethodId;
    this.priceId = data.priceId;
    this.validityDate = data.validityDate ? new Date(data.validityDate) : null;
    this.isActive = data.isActive;
    this.licenseCount = data.licenseCount;
    this.billingCycleAnchor = new Date(data.billingCycleAnchor);
    this.cancelAt = data.cancelAt ? new Date(data.cancelAt) : null;
    this.cancelAtPeriodEnd = data.cancelAtPeriodEnd;
    this.collectionMethod = data.collectionMethod;
    this.created = new Date(data.created);
    this.currency = data.currency;
    this.currentPeriodEnd = new Date(data.currentPeriodEnd);
    this.currentPeriodStart = new Date(data.currentPeriodStart);
    this.customer = data.customer;
    this.daysUntilDue = data.daysUntilDue;
    this.defaultPaymentMethodId = data.defaultPaymentMethodId;
    this.discountId = data.discountId;
    this.endedAt = data.endedAt ? new Date(data.endedAt) : null;
    this.latestInvoiceId = data.latestInvoiceId;
    this.nextPendingInvoiceItemInvoice = data.nextPendingInvoiceItemInvoice
      ? new Date(data.nextPendingInvoiceItemInvoice)
      : null;
    this.pendingSetupIntentId = data.pendingSetupIntentId;
    this.startDate = new Date(data.startDate);
    this.status = data.status;
    this.planId = data.planId;
    this.planAmount = data.planAmount;
    this.planCurrency = data.planCurrency;
    this.planInterval = data.planInterval;
    this.planIntervalCount = data.planIntervalCount;
    this.productName = data.productName;
    this.paymentMethod = data.paymentMethod;
    this.latestInvoice = data.latestInvoice;
  }
}

export default Subscription;

import { GetterTree } from "vuex";
import { AudioState } from "./";
import { RootState } from "../root";

const getters: GetterTree<AudioState, RootState> = {
  total: state => state.totalTime,
  time: state => state.currentTime,
  progress: state => state.progress,
  computedProgress: state => state.currentTime / state.totalTime,
  timeFormatted: state => {
    const seconds = Math.floor(state.currentTime) % 60;
    const mins = Math.floor(state.currentTime / 60) % 60;
    const hours = Math.floor(state.currentTime / 60 / 60) % 60;

    const ss = seconds.toString().padStart(2, "0");
    const ms = mins.toString().padStart(2, "0");
    const hs = hours.toString().padStart(2, "0");
    if (hours > 0) return `${hs}:${ms}:${ss} min`;
    else return `${ms}:${ss} min`;
  },
  isPlaying: state => state.isPlaying,
};

export default getters;


































































































































































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, PropSync } from "vue-property-decorator";
import { hasPermissions } from "@/core/utils/auth";

import { isDev, isProd } from "@/settings";

@Component({
  methods: { hasPermissions },
  computed: {
    isDev() {
      return isDev;
    },
    isProd() {
      return isProd;
    },
  },
})
export default class Menu extends Vue {
  @PropSync("expanded", { type: Boolean }) expandedProp!: boolean;
  @Getter("seminars/ownsSeminar") ownsSeminar!: boolean;
  @Getter("profile/getUserHasPrivileges") hasPrivileges!: boolean;
  @Getter("company/hasCompany") hasCompany!: boolean;

  selectedLink = "";
}

import { SharingUser } from "@/core/store/managed";
import { Session } from ".";

export interface Pause {
  offset: number;
  duration: number;
  //slide: number;
}

export interface TranscriptionSegment {
  text: string;
  offset: number;
  duration: number;
  nBest: string[] | undefined;
  confidence: number;
  words: WordSegment[];
}

export interface TranscriptionSlide {
  text: string;
  offset: number;
  duration: number;
  slide: number;
  words: WordSegment[];
  lookupWords: LookupWord[];
  wpm: number;
  pausesMade: number;
  totalPauseTime: number;
  avgPauseLength: number;
  ppm: number;
  wordsPerMinute?: number; // TODO: Use wpm only
  pausesPerMinute?: number; // TODO: Use ppm only
  avgVolume?: number;
  avgPitch?: number;
  image?: string;
}

export enum LookupWordType {
  default = "default",
  stop = "stop_word",
  filler = "filler_word",
}

export interface WordSegment {
  word: string;
  offset: number;
  duration: number;
  displayWord?: string;
}

export interface LookupWord {
  text: string;
  occurrences: number;
  type: LookupWordType;
}

type AverageData = { averagePitch: number; averageVolume: number };
export interface TimedAverage {
  endSeconds: number;
  startSeconds: number;
  audioAnalysisAverages: AverageData;
}

export interface SessionStatus {
  SessionId: number;
  AnalysisStatus?: string;
  ProgressInPercent: number;
}

export interface SharedSessions {
  user: SharingUser;
  sessions: Session[];
}

export interface BaselineSession {
  id: number;
  name: string;
  uploadedDate: Date;
  averageVolume: number;
  averagePitch: number;
}

export interface SessionScores {
  speechSpeedScore: number;
  pausesScore: number;
  repetitionScore: number;
  fillerWordsScore: number;
  speakingClearlyScore: number;
  overallScore: number;
}

import { GetterTree } from "vuex";
import { ProfileState } from ".";
import { RootState } from "../root";
import { Role, Roles, RoleClaims } from "@/core/models";
import { defaultPfp } from "@/core/utils/constants";

const getters: GetterTree<ProfileState, RootState> = {
  id: state => state.user?.ID.trim(),
  customerId: state =>
    (state.user && state.user.CustomerId && state.user?.CustomerId.trim()) ||
    "",
  paymentMethodId: state =>
    (state.user && state.user?.PaymentMethodId.trim()) || "",
  pfp: state => state.user?.Pfp || defaultPfp,
  isDefaultPfp: state => !state.user?.Pfp,
  isFirstLogin: state => state.user?.IsFirstLogin ?? true,
  showOnboarding: state => state.user?.ShowOnboarding ?? true,
  showTutorial: state => state.user?.ShowTutorial ?? true,
  tutorialStep: state => state.user?.TutorialStep ?? 0,
  isAuthenticated: state => state.isAuthenticated,
  hasSubscription: state => state.hasSubscription,
  isEmployee: state => state.isEmployee,
  ownsCompany: state => state.ownsCompany,
  getUserEmail: state => (state.user && state.user.Email.trim()) || "",
  getUserName: state => (state.user && state.user.Username.trim()) || "",
  getUserRole: state => {
    if (state.user && state.user.Roles) {
      console.log("getUserRole state");
      console.log("User roles:", state.user.Roles);
      return state.user.Roles;
    }
    console.log("Roles.User:", Role.User);
    return [Role.User];
  },
  getUserHasPrivileges: state => {
    if (state.user && state.user.Roles)
      return state.user.Roles.some(role => role.name === "admin");

    return false;
  },
  userEmailVerified: state => (state.user && state.user.EmailVerified) || false,

  // Get roles from ASP.NET Role System
  getUserRoles: state => state.user?.Roles || [],

  hasPermission: state => (permission: RoleClaims) => {
    return state.permissions.some(x => {
      const [type] = x.type.split(":"); // Split string at ":" and use first part
      return type === permission.type;
    });
  },
  hasRole: state => (role: Roles) => {
    return state.userRoles.some(r => r.name === role.name);
  },

  // Test for specific Roles
  isAdmin: state => state.user?.Roles.some(r => r.name === "admin"),
  isFreeUser: state =>
    state.user?.Roles.some(r => r.name === "subscriber-free"),
  isBasicUser: state =>
    state.user?.Roles.some(r => r.name === "subscriber-basic"),
  isStandardUser: state =>
    state.user?.Roles.some(r => r.name === "subscriber-standard"),
  isPremiumUser: state =>
    state.user?.Roles.some(r => r.name === "subscriber-premium"),
  isEnterpriseUser: state =>
    state.user?.Roles.some(r => r.name === "subscriber-enterprise"),

  isModerator: state => state.user?.Roles.some(r => r.name === "moderator"),
  isCoach: state => state.user?.Roles.some(r => r.name === "coach"),
  isTeacher: state => state.user?.Roles.some(r => r.name === "teacher"),
  isStudent: state => state.user?.Roles.some(r => r.name === "student"),

  // Non-User specific
  getAllUsers: state => {
    const admins = state.allUsers.filter(x =>
      x.Roles.some(r => r.name === Role.Admin),
    );
    const users = state.allUsers.filter(x =>
      x.Roles.some(r => r.name === Role.User),
    );
    return [...admins, ...users];
  },

  getAllUsersLoading: state => state.allUsersLoading,
  changeUserRoleLoading: state => state.changeUserRoleLoading,
  changedUserRoleEmail: state => state.changedUserRoleEmail,
  forgotPasswordLoading: state => state.forgotPasswordLoading,
  forgotEmailSent: state => state.forgotEmailSent,
  resetPasswordLoading: state => state.resetPasswordLoading,
  confirmEmailLoading: state => state.confirmEmailLoading,
  confirmEmailSent: state => state.confirmEmailSent,

  createCompanyLoading: state => state.createCompanyLoading,
  createCompanySuccess: state => state.createCompanySuccess,
  editCompanyLoading: state => state.editCompanyLoading,
  upgradeLoading: state => state.upgradeLoading,
  loading: state => state.loading,

  //isCompany: state => state.user && !!state.user.CustomerId,
  permissions: state => (state.user && state.user.Permissions) || [],
  userClaims: state => (state.user && state.user.UserClaims) || [],

  baseline: state => state.baseline,

  subscriptionDetails: state => {
    if (state.user && state.user.subscription) return state.user.subscription;

    return {
      isActive: false,
      startDate: null,
      currentPeriodEnd: null,
      planId: "",
      created: null,
      subscriptionId: "",
      userId: "",
      paymentMethodId: "",
      priceId: "",
      validityDate: null,
      licenseCount: 0,
      billingCycleAnchor: null,
      cancelAt: null,
      cancelAtPeriodEnd: false,
      collectionMethod: "",
      currency: "",
      currentPeriodStart: null,
      customer: "",
      daysUntilDue: null,
      defaultPaymentMethodId: "",
      discountId: "",
      endedAt: null,
      latestInvoiceId: "",
      nextPendingInvoiceItemInvoice: "",
      pendingSetupIntentId: "",
      subStartDate: null,
      status: "",
      planAmount: 0,
      planCurrency: "",
      planInterval: "",
      planIntervalCount: 0,
      productName: "",
      paymentMethod: null,
      latestInvoice: null,
    };
  },
};

export default getters;

import store from "..";
import { GetterTree } from "vuex";
import { RootState } from "../root";
import { CurrentSessionState } from ".";
import { Session } from "@/core/models";

const rounder = (value: number) => Math.round(value * 100) / 100;
const getters: GetterTree<CurrentSessionState, RootState> = {
  limit: state => state.limit,
  loading: state => state.session?.Loading,
  completed: state => state.session?.Completed,

  // Zoom
  zoom: state => state.zoom,
  interval: state => state.interval,
  canZoom: state =>
    state.session?.Slides.length !== 0 &&
    state.session?.Slides.some(slide => slide.image),
  canLiveZoom: state => state.session && state.session?.SessionLength > 60,

  // General
  id: state => state.session?.ID,
  session: state => state.session,
  name: state => state.session?.Name,
  score: state => state.session?.SessionScore,
  textCompleted: state =>
    state.session && state.session.TextAnalysis.Text.length > 0,
  audioCompleted: state => state.session?.AudioAnalysis.IsCompleted,
  length: state => state.session?.SessionLength,
  sharedWith: state => state.session?.SharedWith,
  has: state =>
    state.session !== null &&
    state.session !== undefined &&
    state.session !== Session.Empty(),
  feedback: state => state.session?.VideoFeedback,

  // Audio file
  url: state => state.session?.AudioFile?.URI,
  videoUrl: state => state.session?.VideoFromRecording?.URI,

  // Text analysis
  wpm: state => rounder(state.session?.TextAnalysis.WordsPerMinute || 0),
  keywords: state => state.session?.TextAnalysis.Keywords,
  text: state => state.session?.TextAnalysis.Text,
  sentences: state => state.session?.TextAnalysis.Sentences,
  hasSentence: state =>
    state.session && state.session.TextAnalysis.Sentences.length > 0,
  positive: state => rounder(state.session?.TextAnalysis.PositiveScore || 0),
  negative: state => rounder(state.session?.TextAnalysis.NegativeScore || 0),
  neutral: state => rounder(state.session?.TextAnalysis.NeutralScore || 0),
  lookupWords: state => state.session?.TextAnalysis.LookupWords,
  words: state => state.session?.TextAnalysis.Words || [],

  // Movement analysis
  movement: state => state.session?.MovementAnalysis,
  handAvg: state => state.session?.MovementAnalysis.AverageHandMovement,
  headAvg: state => state.session?.MovementAnalysis.AverageHeadMovement,
  handMovement: state => state.session?.MovementAnalysis.HandMovement,
  headMovement: state => state.session?.MovementAnalysis.HeadMovement,

  // Audio analysis
  pitch: state => state.session?.AudioAnalysis.Pitch,
  pitchAvg: state => rounder(state.session?.AudioAnalysis.AveragePitch || 0),
  volume: state => state.session?.AudioAnalysis.Volume,
  volumeAvg: state =>
    rounder((state.session?.AudioAnalysis.AverageVolume || 0) * 100),
  pauses: state => state.session?.AudioAnalysis.Pauses,
  pausesAvg: state => rounder(state.session?.AudioAnalysis.AveragePauses || 0),
  pausesTotal: state =>
    rounder(state.session?.AudioAnalysis.TotalPauseTime || 0),
  pausesPerMinute: state =>
    rounder(state.session?.AudioAnalysis.PausesPerMinute || 0),
  calcLength: state => state.session?.AudioAnalysis.CalculationLength,
  scores: state => state.session?.SessionScores,

  // Slides
  timestamps: state => state.session?.SlideTimestamps,
  averages: state => state.session?.AudioAnalysis.TimedAverages,
  slides: state => {
    if (state.session?.Slides && state.session?.Slides.length > 0)
      return state.session?.Slides.filter(x => x.duration !== 0);

    return [];
  },

  // Current slide
  currentSlide: state => {
    const slides = state.session?.Slides;
    if (!slides || slides.length == 0) return undefined;

    const time = store.getters["audio/time"] * 1000;
    const total = store.getters["audio/total"] * 1000;

    if (time === 0) return { ...slides[0], index: 0 };
    if (time >= total)
      return { ...slides[slides.length - 1], index: slides.length - 1 };

    let index = slides.findIndex(
      x => x.offset <= time && time < x.offset + x.duration,
    );
    if (index === -1) index = slides.length - 1;

    return { ...slides[index], index };
  },

  // AI Feedback
  aiFeedback: state => state.session?.AiFeedback,
};

export default getters;

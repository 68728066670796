import { helpers } from "vuelidate/lib/validators";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { parseBoolean } from "@/core/utils/parsers";

// Settings for app wide defaults and pre-parsing .env variables
export const isLocal = process.env.NODE_ENV === "development";
export const isDev = process.env.VUE_APP_ENV === "dev";
export const isBeta = process.env.VUE_APP_ENV === "beta";
export const isProd = process.env.VUE_APP_ENV === "prod";
export const apiBaseUrl =
  (isLocal
    ? process.env.VUE_APP_API_BASE_URL || "https://localhost:5001"
    : process.env.VUE_APP_API_BASE_URL) ?? "";

export const apiProxyBaseUrl =
  process.env.VUE_APP_API_PROXY_BASE_URL || "https://dev.api.prezp.com/v1";

// Speech SDK settings
export const speechConfigSettings = {
  speechServiceEndpoint:
    process.env.VUE_APP_SPEECH_SERVICE_URL ||
    "wss://dev.api.prezp.com/v1/speech-service",
  initialSilenceTimeoutMs: "60000",
  enableAudioLogging: false,
  enableTelemetry: false,
  postProcessing: "TrueText",
  speechRecognitionLanguage: "de-DE",
  profanityOption: sdk.ProfanityOption.Raw, // raw = unfiltered
  enableProfanityFilter: true,
  requestWordLevelTimestamps: true, // Allows to request the offset and duration per word
  requestPunctuationBoundary: true,
  requestSentenceBoundary: true,
  requestWordBoundary: true,
  enableDictation: true,
  outputFormat: 1, // extended output (1) needed for nBest and confidence
};

// Hardcoded defaults
// TODO: Define time limit settings for all recorders per category,
//  e.g. recordingSettings.user.maxLen, recordingSettings.feedback.maxLen, etc.
export const recordingSettings = {
  expectedLen: 15 * 60, // Recommended/expected recording time in seconds
  maxLen: 20 * 60, // Max allowed recording time in seconds (TED Talk)
  pauseLen: 2, // Time of silence that is counted as pause
  durationThreshold: 90, // Warn user if he tries to analyse session that is below this duration
};

export const transcriptionSettings = {
  fillerWordsDe: [
    "äh",
    "ähm",
    "hm",
    "mhm",
    "ja",
    "ok",
    "halt",
    "naja",
    "tja",
    "eben",
    "also",
    "doch",
    "schon",
    "irgendwie",
    "vielleicht",
    "genau",
    "mal",
    "quasi",
    "eigentlich",
    "sozusagen",
    "ne",
    "gell",
    "weißt du",
    "meinst du",
    "verstehst du",
  ],
  fillerWordsEn: [
    "ah",
    "uhm",
    "uh",
    "hm",
    "um",
    "ehm",
    "like",
    "basically",
    "well",
    "actually",
    "uhh",
    "umm",
    "er",
    "hmm",
    "seriously",
    "literally",
    "totally",
    "obviously",
    "mean",
    "guess",
    "suppose",
    "something",
    "okay",
    "so",
    "right",
    "uh",
    "huh",
    "mhm",
  ],
};

// Changing these values afterwards may lead to existing users unable to login with the new rules
export const passwordSettings = {
  passwordRules: helpers.regex(
    "passwordRules",
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{12,32}$/,
  ),
  minLength: 12,
  maxLength: 32,
};

export const chatSettings = {
  showSupportFABInsteadChat: parseBoolean(
    process.env.VUE_APP_USE_SUPPORT_FAB,
    false,
  ), // Show a support button & dialog instead of the chat
  chatReadyPollingInterval: 200, // Polling to check if the chat is displayed, in ms
  chatReadyRetries: 3, // Required to make sure the widgets inline style was really applied after init (to allow overriding the style)
};

export const paymentSettings = {
  publishableKey:
    process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY ||
    "pk_test_51HEbvUJCzl1v8E2VPxrrkO9hqq1oev2bvT7EBi8iTzNgSHeSaDOJ9cgNHQoY7o3DOIJj4yJoSWzQc3OQJqRJwTnh00vop9yRpT",
  testMode: process.env.VUE_APP_STRIPE_TEST_MODE || true,
  vatIncluded: false, // Defines if tax is included or excluded in the prices from Stripe (net or gross values)
  defaultVatRate: 19,
};

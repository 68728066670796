














































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import LocalStorage from "@/core/utils/LocalStorage";

type Step = { title: string; page: string };

@Component
export default class TourProgress extends Vue {
  @Getter("profile/tutorialStep") tutorialStep!: number;
  @Getter("profile/showTutorial") showTutorial!: boolean;
  @Action("profile/advanceTutorial") advanceTutorial!: () => void;
  @Action("profile/completeTutorial") completeTutorial!: () => void;

  current = 0;
  minimized = false;
  finishedDialog = false;
  welcomeDialog = false;
  get steps() {
    return [
      { title: this.$t("tour.box.start").toString(), page: "/dashboard" },
      {
        title: this.$t("tour.box.recording").toString(),
        page: "/liverecorder",
      },
      {
        title: this.$t("tour.box.sessions").toString(),
        page: "/sessions/list",
      },
      { title: this.$t("tour.box.dash").toString(), page: "/dashboard" },
      //{ title: this.$t("tour.box.goals").toString(), page: "/goals" },
      //{ title: this.$t("tour.box.recorder").toString(), page: "/recorder" },
    ] as Step[];
  }
  toggleMinimized() {
    this.minimized = !this.minimized;
  }
  openCurrent() {
    //console.log("openCurrent");
    this.openTour(this.steps[this.current], this.current);
  }
  openTour({ page }: Step, i: number) {
    //console.log("openTour");
    //console.log("this.current: ", this.current, " i: ", i);
    if (this.current !== i) return;
    this.$router.push(page + "?tour=true");
  }
  nextStep() {
    console.log("nextStep");
    if (this.current + 1 > this.steps.length) return;
    this.current++;

    if (this.current === this.steps.length) {
      this.completeTutorial();
      this.finishedDialog = true;
    } else this.advanceTutorial();
  }
  justLoggedIn() {
    const shouldShow = LocalStorage.getWelcomeDialog();
    //console.log("shouldShow: ", shouldShow);
    if (!shouldShow) return;
    this.welcomeDialog = true;
  }
  closeWelcomeDialog(showAgain = true, start = true) {
    LocalStorage.setWelcomeDialog(showAgain);
    this.welcomeDialog = false;
    if (start) this.openCurrent();
  }
  closeWelcomeDialogAndStartTour() {
    LocalStorage.setWelcomeDialog(false);
    this.welcomeDialog = false;
    this.openCurrent();
  }
  created() {
    window.addEventListener("tour-next", this.nextStep, false);
    window.addEventListener("user-logged-in", this.justLoggedIn, false);
  }
  beforeDestroy() {
    window.removeEventListener("tour-next", this.nextStep, false);
    window.removeEventListener("user-logged-in", this.justLoggedIn, false);
  }

  @Watch("tutorialStep", { immediate: true })
  stepChanged() {
    //console.log("stepChanged watcher");
    this.current = this.tutorialStep;
  }

  @Watch("$route", { immediate: true })
  routeChanged() {
    //console.log("TourProgress.vue");
    //console.log("routeChanged watcher");
    if (
      this.$route.path === this.steps[this.current]?.page &&
      this.showTutorial
    )
      this.openCurrent();
  }
}

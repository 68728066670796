class SlideTimestamp {
  index: number;
  offset: number;
  duration: number;
  slideIndex: number;
  slideURI: string;

  constructor(data: any) {
    const { index, offset, duration, slideIndex, slideURI } = data;
    this.index = index;
    this.offset = offset;
    this.duration = duration;
    this.slideIndex = slideIndex;
    this.slideURI = slideURI;
  }
}
export default SlideTimestamp;

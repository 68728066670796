import { GetterTree } from "vuex";

import { CompanyState } from ".";
import store from "..";
import { RootState } from "../root";

const getters: GetterTree<CompanyState, RootState> = {
  id: state => state.company?.ID || 0,
  company: state => state.company,
  hasCompany: state => !!state.company,
  isActive: state => state.company?.Subscription?.isActive || false,
  employees: state => state.company?.Employees || [],
  isOwner: state => {
    const myEmail = store.getters["profile/getUserEmail"];
    return state.company?.Owner?.Email === myEmail;
  },
  isEmployee: state => {
    const userEmail = store.getters["profile/getUserEmail"];
    return state.company?.Employees.some(
      employee => employee.Email === userEmail,
    );
  },

  editing: state => state.editing,
  creating: state => state.creating,
  upgrading: state => state.upgrading,
  adding: state => state.adding,
  error: state => state.error,
  getting: state => state.getting,
};

export default getters;

import { ActionTree } from "vuex";
import { RootState } from "../root";
import { AudioState } from "./index";

const actions: ActionTree<AudioState, RootState> = {
  updateTime({ state }, time: number) {
    state.currentTime = time;
  },
  setProgress({ state }, progress: number) {
    state.progress = progress;
  },
  setTotalTime({ state }, time: number) {
    state.totalTime = time;
  },
  play({ state }) {
    state.isPlaying = true;
  },
  pause({ state }) {
    state.isPlaying = false;
  },
};

export default actions;

import Employee from "./Employee";
import Subscription from "../Subscription";

class Company {
  ID: string;
  Name: string;
  Owner?: Employee;
  Subscription?: Subscription | null;
  IsActive: boolean;
  Tier: string;
  Employees: Employee[];
  Managers: Employee[];
  CreatedAt: Date;

  constructor(data: any) {
    const { id, name, subscription, tier, createdAt, companyUsers } = data;

    this.ID = id;
    this.Name = name;

    // Handle subscription (use parse method if necessary)
    this.Subscription = subscription ? new Subscription(subscription) : null;
    this.IsActive = this.Subscription ? this.Subscription.isActive : false;

    this.Tier = tier;
    if (companyUsers)
      this.Employees = (companyUsers as object[]).map(e => new Employee(e));
    else this.Employees = [];

    // Filter managers from Employees and add to Managers array
    this.Managers = this.Employees.filter(e => e.IsManager);

    this.Owner = this.Employees.find(e => e.IsOwner);
    this.CreatedAt = new Date(createdAt);
  }

  // Static parse method to create an instance from raw data
  public static parse(data: any): Company {
    const company = new Company(data);

    // Return the fully constructed Company instance
    return company;
  }
}

export default Company;

import { UserClaims, RoleClaims } from "./interfaces";
import { Subscription } from "./Subscription";
import { Roles } from "@/core/models/interfaces";

class User {
  public ID: string;
  public Pfp: string;
  public Email: string;
  public Username: string;
  public Roles: Roles[];
  public EmailVerified: boolean;
  public IsFirstLogin: boolean;
  public CustomerId: string;
  public PaymentMethodId: string;
  public Permissions: RoleClaims[];
  public UserClaims: UserClaims[];
  public ShowOnboarding: boolean;
  public ShowTutorial: boolean;
  public TutorialStep: number;
  public hasSubscription: boolean;
  public isEmployee: boolean;
  public ownsCompany: boolean;
  public subscription: Subscription | null;

  constructor(
    email: string,
    username: string,
    roles: Roles[] = [],
    emailVerified = false,
    firstLogin = true,
    customerId = "",
    paymentMethodId = "",
    permissions: RoleClaims[] = [],
    userClaims: UserClaims[] = [],
    id = "",
    pfp = "",
    onboarding = true,
    show = false,
    step = 0,
    hasSubscription = false,
    isEmployee = false,
    ownsCompany = false,
    subscription: Subscription | null = null,
  ) {
    this.Email = email;
    this.Username = username;
    this.Roles = roles;
    this.EmailVerified = emailVerified;
    this.IsFirstLogin = firstLogin;
    this.CustomerId = customerId;
    this.PaymentMethodId = paymentMethodId;
    this.Permissions = permissions;
    this.UserClaims = userClaims;
    this.ID = id;
    this.Pfp = pfp;
    this.ShowOnboarding = onboarding;
    this.ShowTutorial = show;
    this.TutorialStep = step;
    this.hasSubscription = hasSubscription;
    this.isEmployee = isEmployee;
    this.ownsCompany = ownsCompany;
    this.subscription = subscription;
  }

  public static parse(data: any): User {
    const hasSubscription = data.subscription?.isActive || false;

    return new User(
      data.email,
      data.username,
      data.roles,
      data.emailVerified,
      data.isFirstLogin,
      data.customerId,
      data.paymentMethodId,
      data.roleClaims || [],
      data.userClaims || [],
      data.id,
      data.profilePicture,
      data.showOnboarding,
      data.showTutorial,
      data.tutorialStep,
      hasSubscription,
      data.isEmployee,
      data.ownsCompany,
      data.subscription,
    );
  }
}

export default User;

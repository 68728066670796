import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.VRMode)?_c(VNavigationDrawer,{staticStyle:{"max-height":"100%","display":"flex","flex-direction":"column"},attrs:{"app":"","mini-variant":_vm.mini && !_vm.isMobile,"permanent":_vm.showLayout && !_vm.isMobile},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{staticClass:"d-flex align-center justify-center px-2 py-4",class:_vm.mini && !_vm.isMobile && 'justify-center',style:(_vm.navHeadingHeight)},[(!_vm.mini || _vm.isMobile)?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/')}}},[(_vm.selectedSeminarLogo.length > 0)?_c('div',{staticClass:"mr-4"},[_c(VImg,{staticStyle:{"max-width":"180px","max-height":"60px"},attrs:{"alt":"Seminar Logo","src":_vm.selectedSeminarLogo}})],1):_c('div',[(!_vm.isDarkMode)?_c('img',{staticClass:"title font-weight-bold app-title flex-grow-1",attrs:{"alt":"Prezp Logo","width":_vm.imageWidth,"src":require("@/assets/prezp_logo.png")}}):_c('img',{staticClass:"title font-weight-bold app-title flex-grow-1",attrs:{"alt":"Prezp Logo","width":_vm.imageWidth,"src":require("@/assets/prezp_logo_alternate.png")}})])]):_vm._e(),_c('div',{staticClass:"d-flex align-center justify-center"},[(!_vm.isMobile)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleMini($event)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.mini ? "mdi-chevron-right" : "mdi-chevron-left"))])],1):_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close-nav')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)]),_c('Menu',{attrs:{"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}}}),(
      _vm.hasPermissions([
        'live_transcription:fast',
        'live_transcription:relaxed',
        'upload:audio',
        'upload:video' ])
    )?_c('div',{staticClass:"flex-1 pb-6 px-2 buttons"},[(
        _vm.hasPermissions([
          'live_transcription:fast',
          'live_transcription:relaxed' ])
      )?_c('RecordSession',{attrs:{"data-tour":"rec-btn","mini":_vm.mini,"label":_vm.$t('upsess.rec'),"route":'/liverecorder'}}):_vm._e(),(_vm.hasPermissions(['upload:audio']))?_c('div',{staticClass:"my-1"}):_vm._e(),(_vm.hasPermissions(['upload:audio']))?_c('UploadAudio',{attrs:{"is-uploading-prop":_vm.isUploading,"mini":_vm.mini},on:{"update:isUploadingProp":function($event){_vm.isUploading=$event},"update:is-uploading-prop":function($event){_vm.isUploading=$event}}}):_vm._e(),(_vm.hasPermissions(['upload:video']))?_c('div',{staticClass:"my-1"}):_vm._e(),(_vm.hasPermissions(['upload:video']))?_c('UploadVideo',{attrs:{"is-uploading-prop":_vm.isUploading,"mini":_vm.mini},on:{"update:isUploadingProp":function($event){_vm.isUploading=$event},"update:is-uploading-prop":function($event){_vm.isUploading=$event}}}):_vm._e()],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
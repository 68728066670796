/* eslint-disable */
export const checkIsMobile = (): boolean => {
  const width = window.innerWidth;
  const devicePixelRatio = window.devicePixelRatio || 1;

  console.log("Device Pixel Ratio:", devicePixelRatio);
  console.log("Inner Width:", width);

  // Modifizierter Check, der die Pixelverhältnisse berücksichtigt
  return width / devicePixelRatio <= 768;
};